import {defineStore} from 'pinia';
import {BanknotesIcon, NewspaperIcon, TicketIcon} from "@heroicons/vue/20/solid";

export const MainLayoutStore = defineStore('main-layout-store-wx3IN', {
    state: () => ({
        sidebarOpen: false,
        breadcrumbs: [],
        currentSubMenuName: '', //当前的二级菜单, 用于判断是否展开菜单
        menus: [
            {
                name: '0afcBIWQlJ',
                tips: 'cnDpImVfDd',
                children: [
                    {
                        name: 'phcYB1A1GI',
                        tips: 'WEqETCS6Uw',
                        href: '/qual/alipay/page',
                    },
                    // {
                    //     name: 'pkgysH5QAI',
                    //     tips: 'iLsyUOLvM2',
                    // }
                ]
            },
            {
                name: 'Q0b62VFBZS',
                children: [
                    {
                        name: 'KIVN0tm8BT',
                        href: '/wallet/page',
                    },
                    {
                        name: 'BF57W9iucJ',
                        href: '/wallet/cny/page',
                    },
                    {
                        name: 'f8DJHjI5EY',
                        href: '/wallet/cny-bill/page',
                    },
                    {
                        name: 'G2f4ueqZnv',
                        href: '/wallet/usdt/page',
                    },
                    {
                        name: 'KImXk213Xp',
                        href: '/wallet/usdt-bill/page',
                    },
                ]
            },
            {
                name: 'btPVYPIhGh', tips: '',
                children: [
                    {
                        name: 'XkxomIRFj2',
                        tips: '',
                        href: '/do/cny-do-list/page',
                    },
                ]
            },
            // {
            //     name: 'sB0k1YkEan',
            //     tips: '41cepV51pN',
            // },
            {
                name: '4lh7q8TB3m', tips: '',
                children: [
                    {
                        name: 'OYFR9g76aE',
                        tips: '',
                        href: '/child-members/page',
                    },
                ]
            },
            {
                name: '5fg66b9Z9o',
                href: '/user-center/page',
            },
        ],
    }), actions: {
        updateCurrentSubMenu(subMenuName) {
            this.currentSubMenuName = subMenuName;
        },
        parentMenuOpen(parentMenu) {
            const subMenus = parentMenu.children || [];
            if (subMenus.length < 1) {
                return false;
            }

            const route = useRoute();

            for (const subMenu of parentMenu.children) {
                //如果页面没有指定 上级, 执行自动匹配规则
                if (route.path === subMenu.href) {
                    //匹配成功 更新当前2级菜单
                    this.currentSubMenuName = subMenu.name
                    return true
                }
            }

            //手动指定 要打开的菜单
            if (!this.currentSubMenuName) {
                return
            }

            for (const subMenu of parentMenu.children) {
                parentMenu.open = true;
                if (this.currentSubMenuName === subMenu.name) {
                    return true
                }
            }

            return false;
        }
    }
});
