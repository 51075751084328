import {UserStore} from '~/store/user-store';
import {EnvStore} from "~/common-store/env-store";
import {I18nStore} from "~/common-store/i18n-store";
import {TipsStore} from "~/common-store/tips-store";
import {EditModeStore} from "~/common-store/edit-mode-store";
import {LangStore} from "~/common-store/lang-store";
import uuid from "~/utils/uuid";
import {CurrencyStore} from "~/common-store/currency";
import {PlatformExchangeRateStore} from "~/common-store/platform-exchange-rate";
import {GlobalPlatformInfoStore} from "~/store/global-platform-info-store";
import routeUtil from "~/utils/route";

export default defineNuxtRouteMiddleware(async (to, from) => {
    console.log(uuid.id(10));
    console.log(uuid.id(5));

    //加载配置
    const envStore = EnvStore();
    envStore.loadConfig()

    //加载后台信息
    const globalPlatformInfoStore = GlobalPlatformInfoStore();
    await globalPlatformInfoStore.fetchPlatformInfo()

    //加载 i18n文件
    const i18nStore = I18nStore();
    await i18nStore.loadI18n();

    //加载 tips
    const tipsStore = TipsStore();
    await tipsStore.loadTips();

    //加载 语言
    const langStore = LangStore();
    await langStore.loadLangInfoFromStorage();

    const userStore = UserStore();

    //如果参数中有 jwt 尝试自动登录
    const query = routeUtil.getQuery();
    if (query.jwt && query.imAutoLogin) {
        await userStore.imAutoLogin();
    }

    // 加载用户信息
    await userStore.loadDataFromLocal();

    // 加载编辑模式是否打开标识
    const editModeStore = EditModeStore();
    await editModeStore.loadFromLocal();

    // 加载币种信息
    const currencyStore = CurrencyStore();
    await currencyStore.fetchCurrencyList();

    // 加载汇率信息
    const platformExchangeRateStore = PlatformExchangeRateStore();
    await platformExchangeRateStore.fetchExchangeRateList();

})
